//vars 
$orange: #fe6a48;
$dblue: #09131d;
$grey: #2d3143;

html,body{
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, 'Titillium Web';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $dblue;
  font-family: 'Titillium Web', sans-serif;
}
p,div{
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
}
a{
  color: $orange;
  &:hover{ 
    color: #fff; 
  }
}
h1,h2,h3{
  color: $orange;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden{
  display:none;
}

iframe{
  max-width: 100% !important;
}

.bstyle{
  padding: 5px 10px;
  color: #000;
  background: $orange;
  border: 1px solid $orange;
  border-radius: 3px;
  &:hover{
    background: $dblue;
    color: $orange;
    border: 1px solid $orange;
  }
}

button{
  @extend .bstyle;
}

header{
  padding: 30px 0;

}
.main-content{
  background: $grey;
  padding: 30px 0;
}

.encrypter-wrapper{
  
  //padding: 15px;
  .heading{
    margin-bottom: 50px;
  }
  .tab-container{
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        display: inline-block;
        .tbtn{
          border: 1px solid $orange;
          border-bottom-color: $orange;
          padding: 5px 10px;
          background: rgb(175, 175, 175);
          cursor: pointer;
          font-weight: bold;
          &.active{
            background: $orange;
            color: $dblue;
          }
        }
      }
    }
  }
  .form-wrapper{
    padding: 25px 15px;
    border: 1px solid $orange;
    .iwrapper{
      margin: 15px 0px;
      input{
        padding: 5px 10px;
      }
      h5{
        color: $dblue;
      }
    }
    .tab-content{
      form{
        background: rgb(221, 221, 221);
        padding: 15px;
      }
      .message{
        color: green;
        margin-bottom: 15px;
      }
      input,textarea{
          width: 100%;
      }
      textarea{
        padding: 5px 8px;
      }
      button{
          display:inline-block;
      }
    }
  }
}

.footer{
  //background: #2d3143;
  min-height: 50px;
  padding: 30px 0;
  .top{
    margin-bottom: 15px;
    .adrow{
      overflow: hidden;
      
    }
  }
  .bottom{
    #cp{
      font-size: 12px;
    }
  }
}
